@font-face {
  font-family: 'SF Pro Display', sans-serif;
  src: url("../fonts/SF/r/sf-pro-display-regular-webfont.woff");
  src: url("../fonts/SF/r/sf-pro-display-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/SF/r/sf-pro-display-regular-webfont.woff") format("woff"), url("../fonts/SF/r/sf-pro-display-regular-webfont.ttf") format("truetype"), url("../fonts/SF/r/sf-pro-display-regular-webfont.svg#helvetica-webfont") format("svg");
  fond-display: optional;
  font-weight: normal;
  font-style: normal; }

h1, h2, h3, h4, h5, h6, p, a {
  font-family: SF Pro Display, Arial, SansSerif; }

h1 {
  font-size: 40px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 50px;
  color: #F24604; }

h2 {
  font-size: 40px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 50px;
  color: #F24604; }

h3 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #F24604; }

h4 {
  font-size: 20px;
  text-align: center; }

h5 {
  font-size: 20px;
  margin-bottom: 30px; }

h6 {
  font-size: 20px;
  margin: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  color: white; }

p {
  color: #707070; }

a {
  cursor: pointer;
  text-decoration: none;
  color: #F24604; }
  a:hover {
    color: #F24604; }
  a:visited {
    color: rgba(0, 52, 89, 0.5); }

.flex__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row; }

.flex__row_flexstart {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.flex__row_between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.flex__row_around {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.flex__column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }

.flex__column_center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.border__none {
  border: none; }

.block__wrapper {
  padding: 15px; }

.btn {
  -webkit-border-radius: 0;
          border-radius: 0;
  background: none; }

.btn-default {
  border: 1px solid #F24604;
  color: #F24604; }
  .btn-default:hover {
    color: #F24604;
    text-shadow: none;
    background: #F24604;
    color: white; }

.btn-primary {
  font-size: 20px;
  padding: 13px 20px;
  color: #F24604;
  border: 1px solid #F24604;
    -webkit-border-radius: 0;
            border-radius: 0;
  text-shadow: none; }
  .btn-primary:hover {
    background: none #F24604;
    color: white;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s; }
  .btn-primary:focus {
    background: none #F24604;
    color: #003459;
    border: 1px solid #003459;
    outline: none; }

body {
  background-color: #f5f5f5; }

.header__topper {
  height: 30px;
  padding: 4px 0;
  display: block;
  position: relative;
  background-color: #003459; }

.header__social, .header__contacts {
  list-style: none;
  margin: 0;
  padding: 0; }
  .header__social li, .header__contacts li {
    padding-left: 50px; }
    .header__social li:first-child, .header__contacts li:first-child {
      padding-left: 0; }
    .header__social li a, .header__contacts li a {
      color: white; }
      .header__social li a:hover, .header__contacts li a:hover {
        text-decoration: none;
        color: #F24604;
        -webkit-transition: color .5s;
        -o-transition: color .5s;
        transition: color .5s; }

.header__contacts {
  margin-left: 100px; }

.brandbar .flexbrand {
  height: 80px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .brandbar .flexbrand .brandbar__logo {
    width: 100%; }
  .brandbar .flexbrand .brandbar__search input {
    border: 1px solid #F24604;
    -webkit-border-radius: 0;
            border-radius: 0; }
  .brandbar .flexbrand .brandbar__phone {
    list-style: none;
    color: #F24604;
    margin: 0;
    z-index: 1075; }
    .brandbar .flexbrand .brandbar__phone a {
      font-size: 16px;
      color: #F24604; }
  .brandbar .flexbrand .brandbar__auth {
    z-index: 1075;
    background: none; }
    .brandbar .flexbrand .brandbar__auth .fa {
      color: #F24604; }
    .brandbar .flexbrand .brandbar__auth .log-in {
      margin: 0 5px; }
  .brandbar .flexbrand .brandbar_shopping span {
    color: white;
    background-color: #003459;
    padding: 3px 6px;
    -webkit-border-radius: 16px;
            border-radius: 16px; }

.sitenavigation {
  z-index: 1070;
  background: #F24604 !important;
  height: 50px; }
  .sitenavigation .sitenavigation__collapsad button {
    background: white !important;
    border: none;
      -webkit-border-radius: 5px;
              border-radius: 5px; }
    .sitenavigation .sitenavigation__collapsad button span.icon-bar {
      border: 1px solid #F24604 !important; }
  .sitenavigation .sitenavigation__list li {
    color: white;
    padding: 10px 25px;
    text-align: center; }
    .sitenavigation .sitenavigation__list li a {
      color: white;
      text-shadow: none;
      padding: 5px 1px;
      border-bottom: 1px solid #F24604; }
      .sitenavigation .sitenavigation__list li a:hover {
        color: white;
        border-bottom: 1px solid white; }
  .sitenavigation .sitenavigation__list .active > a {
    color: white;
    background: none !important;
    border-bottom: 1px solid white;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .sitenavigation .sitenavigation__list .active:hover > a {
    color: white; }

.breadcrumb {
  margin-bottom: 20px;
  background-color: #f5f5f5; }
  .breadcrumb > ol {
    list-style: none;
    padding: 8px 15px;
    -webkit-border-radius: 0;
            border-radius: 0;
    margin: 0; }
    .breadcrumb > ol > li {
      display: inline-block; }
      .breadcrumb > ol > li + li:before {
        padding: 0 5px;
        color: #ccc;
        content: "/\00a0"; }

.comercial-item {
  display: block;
  position: relative;
  height: 190px;
  overflow: hidden;
  margin-bottom: 20px; }

.comercial-item__image {
  width: 100%; }

.comercial-item__header {
  display: block;
  position: absolute;
  height: 50px;
  width: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0; }
  .comercial-item__header h4 {
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    color: black; }

.maincatalog {
  position: relative;
  background-color: rgba(0, 52, 89, 0.8);
  height: 100%;
  padding: 10px 0 1px; }
  .maincatalog .category-top-menu .list-group-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  .maincatalog .category-top-menu li {
    position: static;
    background: none;
    border: none;
    margin: 0;
    padding: 15px; }
    .maincatalog .category-top-menu li:hover {
      background-color: rgba(255, 255, 255, 0.03); }
    .maincatalog .category-top-menu li:hover > a {
      color: #F24604; }
    .maincatalog .category-top-menu li:hover > .fa-chevron-right {
      color: #F24604; }
  .maincatalog .category-top-menu .fa-chevron-right {
    color: white; }
  .maincatalog .category-top-menu a {
    color: white;
    font-size: 16px; }
    .maincatalog .category-top-menu a:hover {
      text-decoration: none; }
  .maincatalog .popover {
    min-width: 40vw !important;
    background-color: #003459; }
    .maincatalog .popover .submenublock {
      padding: 10px 5px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
    .maincatalog .popover .popover-title {
      padding: 25px 0 0px;
      text-transform: uppercase;
      background: none;
      border: none;
      font-weight: bold; }
    .maincatalog .popover .popover-content {
      list-style: none; }
      .maincatalog .popover .popover-content li {
        padding: 4px 10px; }

.mainslider {
  max-height: 380px;
  overflow: hidden; }

.tab-panel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 25px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 52, 89, 0.5); }
  .tab-panel a {
    color: #003459;
    font-size: 16px;
    padding: 5px 25px !important; }
    .tab-panel a:hover {
      background-color: rgba(112, 112, 112, 0.5) !important;
      color: white; }
  .tab-panel > .active {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row; }
    .tab-panel > .active > a {
      color: white !important;
      background-color: #707070 !important;
      text-shadow: none; }
  .tab-panel li:hover a {
    background-color: white;
    border-bottom: 1px solid rgba(0, 52, 89, 0.5); }

.tab-pane {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative; }

.tab-content .active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around; }

.thumbnail {
  position: relative;
  overflow: hidden;
  max-width: 280px; }
  .thumbnail .price {
    display: block;
    font-size: 28px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: -10px; }
  .thumbnail .product-description {
    padding: 10px; }
    .thumbnail .product-description h3 {
      white-space: nowrap;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis; }
    .thumbnail .product-description p {
      height: 60px;
      overflow: hidden;
      -o-text-overflow: clip;
         text-overflow: clip;
      text-align: justify;
      margin: 0; }
  .thumbnail:hover .product__filter {
    display: block; }
  .thumbnail .product__filter {
    position: absolute;
    top: -100%;
    right: 0;
    background-color: rgba(242, 70, 4, 0.8);
    height: 100%;
    width: 100%;
    z-index: 9999;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
    .thumbnail .product__filter span {
      display: block;
      margin-top: 55%;
      font-size: 20px;
      color: white;
      text-align: center; }
    .thumbnail .product__filter .product__icons {
      margin-top: 20px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-around;
          -ms-flex-pack: distribute;
              justify-content: space-around; }
      .thumbnail .product__filter .product__icons a {
        color: white; }
  .thumbnail:hover .product__filter {
    top: 0; }

.product-flags {
  position: absolute;
  list-style: none;
  color: white;
  font-size: 16px;
  margin: 0;
  padding: 0;
  top: 25px;
  left: 25px; }
  .product-flags .flag-news {
    background-color: #30b800;
    padding: 3px 19px; }
  .product-flags .flag-pop {
    background-color: #003459;
    padding: 3px 19px; }
  .product-flags .flag-recomends {
    background-color: #F24604;
    padding: 3px 19px; }

.advantages {
  background-color: #003459;
  padding: 30px 30px 60px; }
  .advantages img {
    max-width: 70px; }
  .advantages h2, .advantages h3, .advantages p {
    color: white; }

.about__items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.about__text {
  max-height: 260px;
  white-space: normal;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis; }

.about__button {
  margin-top: 30px;
  margin-bottom: 10px; }

.news__items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .news__items .thumbnail {
    background: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    padding: 5px; }
    .news__items .thumbnail .caption h3 {
      white-space: nowrap;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis; }
    .news__items .thumbnail .caption p {
      max-height: 120px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      text-align: justify;
      white-space: pre-line;
      font-size: 14px; }
    .news__items .thumbnail .caption a {
      display: block;
      text-align: right;
      color: #F24604; }
    .news__items .thumbnail:hover {
      -webkit-box-shadow: 0px 0px 3px rgba(242, 70, 4, 0.5) inset;
              box-shadow: 0px 0px 3px rgba(242, 70, 4, 0.5) inset; }

.news__button {
  margin-top: 30px;
  margin-bottom: 70px; }

.footer {
  background-color: #F24604;
  color: white;
  padding: 30px; }

.footer__logo a {
  margin: 0; }

.footer__logo img {
  display: block;
  margin: 30px auto; }

.footer__list {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0; }
  .footer__list li {
    font-size: 14px;
    padding-bottom: 10px;
    cursor: pointer; }
    .footer__list li a {
      color: white; }
      .footer__list li a:hover {
        text-decoration: none;
        color: #003459; }

.footer__soc {
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .footer__soc li {
    padding-left: 20px; }
    .footer__soc li:first-child {
      padding-left: 0; }
    .footer__soc li a {
      color: white; }
    .footer__soc li:hover a {
      -webkit-transition: color .5s;
      -o-transition: color .5s;
      transition: color .5s;
      color: #003459; }

.postfooter {
  background-color: #707070;
  padding: 15px; }
